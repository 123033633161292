const es = {
    actionLogin: "LOGIN",
    actionContinue: "CONTINUE",
    actionConfirmCon: "CONFIRM & CONTINUE",
    actionUpdate: "UPDATE",
    actionEdit: "EDIT",
    actionDelete: "DELETE",
    actionBack: "COME BACK",
    actionSave: "SAVE",
    actionCreate: "CREATE",
    actionYes: "YES",
    actionNo: "No",
    actionAdd: "ADD",
    actionCancel: "CANCEL",
    actionAccept: "ACCEPT",
    actionSelect: "SELECT",

    actionNew: "NEW ENTRY",
    actionExit: "Logout",

    actionShow: "Show",
    actionClose: "Close",

    msgEmail: "Email",
    msgPassword: "Password",

    msgSection1: "Innovation Topics",
    msgSection2: "Financing Programmes",
    msgSection3: "Invitations to EFIC",
    msgSection4: "Project Proposals",
    msgSection5: "Projects",
    msgSection6: "Innovation Contacts",

    msgSearch: "Search field",

    msgName: "Name",
    msgTopicName: "Topic's name",
    msgTopicNameObl: "Topic's name *",

    msgOrigin: "Origin",
    msgArea: "Area",
    msgDescription: "Description",
    msgWhyTrend: "Why consider this \"topic/trend\" ?",
    msgReference: "Reference",
    msgPostedBy: "Posted by",
    msgWhen: "When",

    msgProgrammeObl: "Programme *",
    msgDeadlineObl: "Deadline *",

    msgProgramme: "Programme",
    msgCall: "Call",
    msgDeadline: "Deadline",
    msgTopics: "Topics",
    msgTypeProject: "Type of projects",
    msgTypeConsotium: "Type of consortiums",
    msgBudget: "Budget",
    msgCofinancing: "Co-financing",

    msgInvitationObl: "Invitation *",
    msgCallDeadlineObl: "Call deadline *",

    msgInvitation: "Invitation",
    msgInvitationDate: "Invitation date",
    msgCallDeadline: "Call deadline",
    msgProjectFullTitle: "Project full title",
    msgAbstractPage: "Abstract or one page proposal (link)",
    msgProjectLed: "Project led by",
    msgAnswerDeadline: "Answer deadline",
    msgDecision: "EFIC's board decision",
    msgDecisionDate: "Date of EFIC's board decision",

    msgProjectTitleObl: "Project title *",

    msgProjectTitle: "Project title",
    msgProjectIdea: "Project idea",
    msgCollaborationRequest: "Collaboration requested",

    msgEntityObl: "Entity *",

    msgEntity: "Entity",
    msgEntityType: "Type of entity",
    msgCountry: "Country",
    msgWeb: "Web",
    msgShortDescription: "Short description",
    msgPotencialCollab: "Potential collaboration",
    msgContactPerson: "Contact person/s",

    msgAcronymObl: "Acronym *",
    msgStartYearObl: "Start year *",

    msgAcronym: "Acronym",
    msgTitle: "Title",
    msgStartYear: "Start year",
    msgEndYear: "End year",
    msgLeader: "Leader",


    /* MODAL */
    msgAreSure: "Are you sure?",

    msgSuccess: "Data update succesfully",
    msgDeleteSuccess: "Data delete succesfully",

    errorOblData: "Fill all mandatory fields",
    errorEmailFormat: "Email format error",
    errorUserIncorrect: "Email/password incorrect",
    errorSend: "Communication error please wait and retry later",
    errorLength: "The number of characters has been exceded",

    msgCopyright: "Copyright&copy;2021 CENFIM. All rights reserved",

    msgFooterLegal: "Legal notice",
    msgFooterPrivacy: "Privacy",
    msgFooterCookies: "Cookie Policy",
    msgFooterCondic: "Condiciones generales del servicio",
    msgFooterCalidad: "Política de calidad",
}

export default es;