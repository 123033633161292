import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import ButtonFlat from '../components/ButtonFlat';
import { LanguageContext } from '../components/providers/LanguageProvider';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import { Card, ContainerFlex, CustomInput } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { verifyEmailFormat } from '../components/util/UserUtil';
import logo_text from '../img/logo_text.png';
import logo_cenfim from '../img/logo-cenfim-color.svg';


const LoginView = () => {
    const [formData, setFormData] = useState({ email: "", password: "" })
    const { dictionary } = useContext(LanguageContext)
    const { dispatch } = useContext(UserContext);

    let history = useNavigate();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const sendRequest = () => {
        if (formData.email !== "" && formData.password !== "") {
            if (verifyEmailFormat(formData.email)) {
                //Send request
                HttpConnection(HttpOperations.login,
                    result => {
                        history("./", { replace: true });
                        dispatch({ action: UserAction.login, data: result.item })
                    },
                    error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorUserIncorrect" } }),
                    HttpMethod.post, formData);
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorEmailFormat" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
        }
    }

    return (
        <ContainerFlex width="12" minheight="100vh" align="center" justify="center" color="color-primary">
            <Card width="12" lg="4" color="color-white" px="16" justify="center">

                <ContainerFlex width="12" align="center">
                    <img style={{ padding: "16px" }} alt="efic-logo" width="50%" src={logo_text} />
                    <img style={{ padding: "16px" }} alt="efic-logo" width="50%" src={logo_cenfim} />
                </ContainerFlex>

                <CustomInput onChange={onChange} inputmv="16" titleph="16" title={"msgEmail"} placeholder={dictionary["msgEmail"]} type="email" name="email" value={formData.email} />
                <CustomInput onChange={onChange} inputmv="8" titleph="16" title={"msgPassword"} placeholder={dictionary["msgPassword"]} type="password" name="password" value={formData.password} />

                <ButtonFlat mt="48" action={sendRequest} label="actionLogin" fontColor="color-white" />
            </Card>
        </ContainerFlex>
    );
};

export default LoginView;