import './css/normalize.css';
import './css/bootstrap-grid.css';
import './css/App.css';

import { LanguageProvider } from './components/providers/LanguageProvider';
import { UserContext, UserProvider } from './components/providers/UserProvider';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginView from './views/LoginView';

import { Fragment, useContext } from 'react';
import { NotificationView } from './components/util/NotificationUtil';
import ModalView from './components/util/ModalUtil';
import { Container } from './components/styled/Styled';
import Header from './components/Header';
import { Footer } from './components/Footer';
import { HttpOperations } from './components/util/HttpConnection';
import { contacsDefault, contactsFields, financingDefault, financingFields, innovationDefault, innovationFields, invitationDefault, invitationFields, projectFields, projectsDefault, proposalsDefault, proposalsFields } from './constants/model';
import SectionBaseView from './views/SectionBaseView';

const SectionOneView = (props) => <SectionBaseView {...props} />;
const SectionTwoView = (props) => <SectionBaseView {...props} />;
const SectionThreeView = (props) => <SectionBaseView {...props} />;
const SectionFourView = (props) => <SectionBaseView {...props} />;
const SectionFiveView = (props) => <SectionBaseView {...props} />;
const SectionSixView = (props) => <SectionBaseView {...props} />;

const SwitchView = () => {
  const { state } = useContext(UserContext);

  return <Fragment>
    <Container width="12" height="100vh" overflowY="auto" color="color-white">
      <Router basename="">
        {
          state.login ?
            <Fragment>
              <Header />
              <Container width="12" minheight="calc(100vh - 54px)" color="color-primary" >
                <Routes>
                  <Route exact path="/" element={<SectionOneView label="msgSection1" operation={HttpOperations.topics} fields={innovationFields} itemDefault={{ ...innovationDefault }} />} />
                  <Route exact path="/programmes" element={<SectionTwoView label="msgSection2" operation={HttpOperations.financing} fields={financingFields} itemDefault={{ ...financingDefault }} />} />
                  <Route exact path="/invitations" element={<SectionThreeView label="msgSection3" operation={HttpOperations.invitations} fields={invitationFields} itemDefault={{ ...invitationDefault }} />} />
                  <Route exact path="/proposals" element={<SectionFourView label="msgSection4" operation={HttpOperations.proposals} fields={proposalsFields} itemDefault={{ ...proposalsDefault }} />} />
                  <Route exact path="/projects" element={<SectionFiveView label="msgSection5" operation={HttpOperations.projects} fields={projectFields} itemDefault={{ ...projectsDefault }} />} />
                  <Route exact path="/contacts" element={<SectionSixView label="msgSection6" operation={HttpOperations.contacts} fields={contactsFields} itemDefault={{ ...contacsDefault }} />} />
                </Routes>
              </Container>
              <Footer />
            </Fragment>
            :
            <Routes>
              <Route exact={true} path="/" element={<LoginView />} />
            </Routes>
        }
      </Router>
      {state.notification.show ? <NotificationView /> : null}
    </Container>
    {state.modal.show ? <ModalView children={state.modal.body} /> : null}
  </Fragment>
}

const App = () => <LanguageProvider children={<UserProvider children={<SwitchView />} />} />

export default App;
