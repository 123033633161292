import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { UserAction, UserContext } from './providers/UserProvider';
import { Button, Container, ContainerFlex, TextLocalized } from './styled/Styled';

import logo from '../img/logo.png';

const Header = () => {
    let history = useNavigate();
    let location = useLocation();
    let { dispatch } = useContext(UserContext);

    const fontWeight = (word) => location.pathname.includes(word) ? "600" : "300";

    return (
        <ContainerFlex width="12" ph="32" pv="8" color="color-white" fontColor="color-black" align="center" justify="space-between">
            <Container>
                <img alt="efic-logo" src={logo} height="48px" />
                <Container fontWeight="600" children="Innovation Steering Committee Platform – ISCP" />
            </Container>
            <ContainerFlex align="center">
                <Button onClick={() => history("/")} fontWeight={location.pathname === "/" || location.pathname === "" ? "600" : "300"} mh="4" px="16" textAlign="center" children={<TextLocalized children="msgSection1" />} />
                <Button onClick={() => history("/programmes")} fontWeight={fontWeight("programmes")} mh="4" pv="16" ph="8" textAlign="center" children={<TextLocalized children="msgSection2" />} />
                <Button onClick={() => history("/invitations")} fontWeight={fontWeight("invitations")} mh="4" pv="16" ph="8" textAlign="center" children={<TextLocalized children="msgSection3" />} />
                <Button onClick={() => history("/proposals")} fontWeight={fontWeight("proposals")} mh="4" pv="16" ph="8" textAlign="center" children={<TextLocalized children="msgSection4" />} />
                <Button onClick={() => history("/projects")} fontWeight={fontWeight("projects")} mh="4" pv="16" ph="8" textAlign="center" children={<TextLocalized children="msgSection5" />} />
                <Button onClick={() => history("/contacts")} fontWeight={fontWeight("contacts")} mh="4" pv="16" ph="8" textAlign="center" children={<TextLocalized children="msgSection6" />} />
                <Button onClick={() => dispatch({ action: UserAction.logout })} mh="4" ph="16" pv="4" color="color-black" fontColor="color-white" textAlign="center" children={<TextLocalized children="actionExit" />} />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Header;