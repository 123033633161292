import { useContext, useState } from 'react';
import { LanguageContext } from './providers/LanguageProvider';
import { Container, Card, ContainerFlex, TextLocalized, Button, CustomInput, AreaInput } from './styled/Styled';

export const FormTextFieldView = ({ item, field, type = "text", maxLength, onChange }) => {
    const { dictionary } = useContext(LanguageContext);

    return <ContainerFlex width="12" lg="6" ph="16" justify="flex-end">
        <CustomInput width="12" onChange={onChange} inputmv="8" titleph="16" title={field.label} placeholder={dictionary[field.label]} type={type} name={field.param} value={item[field.param]} />
        {
            maxLength ?
                <Container flex width="auto" fontColor={(item[field.param] ? item[field.param].length : 0) > maxLength ? "color-red" : "color-black"} fontSize="10px" justify="flex-end" children={`${item[field.param] ? item[field.param].length : 0}/${maxLength}`} />
                : null
        }
    </ContainerFlex>
}

export const FormTextAreaView = ({ item, field, maxLength, onChange }) => {
    const { dictionary } = useContext(LanguageContext);

    return <ContainerFlex width="12" lg="12" ph="16" justify="flex-end">
        <AreaInput width="12" onChange={onChange} inputmv="8" titleph="16" title={field.label} placeholder={dictionary[field.label]} type="text" name={field.param} value={item[field.param]} />
        <Container flex width="auto" fontColor={(item[field.param] ? item[field.param].length : 0) > maxLength ? "color-red" : "color-black"} fontSize="10px" justify="flex-end" children={`${item[field.param] ? item[field.param].length : 0}/${maxLength}`} />
    </ContainerFlex>
}

export const FormMultipleSelected = ({ item, options = [], field, setFormData }) => {
    const [clicked, setClicked] = useState(false);
    const changeArea = (option) => {
        let areasAux = [...item[field.param]];
        if (item[field.param].findIndex(it => it.id === option.id) !== -1) {
            areasAux = areasAux.filter(it => it.id !== option.id);
        } else {
            areasAux.push(option);
        }
        setFormData({ ...item, [field.param]: areasAux });
    }

    const isSelected = (option) => item[field.param].findIndex(it => it.id === option.id) !== -1;

    return <Container width="12" lg="6" ph="16" pv="8">
        <Card width="12" flex
            minheight="58px"
            align="center"
            radius="12px"
            color={clicked || item[field.param] !== null ? "color-white" : "color-gray"}
            border="solid"
            borderColor={clicked || item[field.param] !== null ? "color-primary" : "color-gray-dark"}
            borderWidth="1px"
            onFocus={() => setClicked(true)} onBlur={() => setClicked(false)}>
            <Container width="12">
                {
                    clicked || item[field.param] !== null
                        ? <Container fontSize="10px" ph="16" pt="8" fontColor={"color-primary"} textTransform="uppercase" children={<TextLocalized children={field.label} />} />
                        : null
                }
            </Container>
            <ContainerFlex width="12" ph="8" pb="8">
                {
                    options.map(option => <Button onClick={() => changeArea(option)} key={option.id} px="8" mx="8" children={option.name}
                        color={isSelected(option) ? "color-black" : "color-white"}
                        fontColor={isSelected(option) ? "color-white" : "color-black"}
                        borderColor="color-black"
                        border="solid" borderWidth="1px" />)
                }
            </ContainerFlex>
        </Card>
    </Container>

}

export const FormDisabledFieldView = ({ item, field, value }) => {
    const { dictionary } = useContext(LanguageContext);

    return <Container width="12" lg="6" ph="16">
        <CustomInput disabled inputmv="8" titleph="16" title={field.label} placeholder={dictionary[field.label]} type="text" name="" value={value} />
    </Container>
}