import React, { useContext, useEffect } from 'react';
import { UserAction, UserContext } from '../providers/UserProvider';
import { Card, TextLocalized, ContainerFlex } from '../styled/Styled';

export const notificationDefault = { show: false, color: "color-red", message: "" }
export const NotificationView = () => {
    const { state, dispatch } = useContext(UserContext);
    useEffect(() => {
        let timer = setTimeout(() => dispatch({ action: UserAction.showNoti, data: { ...state.notification, show: false } }), 3000)
        return () => clearTimeout(timer);
    })

    return <ContainerFlex width="12" justify="center" position="fixed" bottom="50%" elevation="200">
        <Card px="16" fontColor="color-white" mt="32" textAlign="center"
            color={state.notification.color}
            children={<TextLocalized children={state.notification.message} />} />
    </ContainerFlex>
}
