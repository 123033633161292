import { getUserToken } from '../util/UserUtil';

export const HttpOperations = {
    login: "auth",

    topics: "topics",
    financing: "financing",
    invitations: "invitations",
    proposals: "proposals",
    projects: "projects",
    contacts: "contacts",
}

export const HttpMethod = {
    get: "GET",
    post: "POST",
    patch: "PATCH",
    delete: "DELETE",
    put: "PUT"
}

export const HttpConnection = async (operation, success, error, method = "GET", data = null, checkingAuth = false) => {
    let token = getUserToken();

    const request = await fetch(`${serverUrlEndpoint}${operation}`, {
        method: method,
        body: data === null ? null : JSON.stringify(data),
        headers: {
            'Authorization': `${token === null || checkingAuth ? '' : token}`,
            'Content-Type': 'application/json',
        }
    })

    let item = Object()

    if (!request.ok) {
        error(request.status);
    } else {
        try {
            item = await request.json();
        } catch (error) {
            item = {};
        }
        success(item);
    }
}

const debugMode = false;
//movil: 172.20.10.8
//wifi: 192.168.5.39

export const serverUrlEndpoint = debugMode ? "http://192.168.5.39/cenfim/efic-api/public/api/" : "https://iscp.ambitcluster.org/efic-api/public/api/";
