import { UserData } from '../providers/UserProvider';
import AES from 'crypto-js/aes';
import encUTF8 from 'crypto-js/enc-utf8';

export const checkLoginUser = (user) => {
    var storageUser = getStorageUser();
    return storageUser && storageUser.id === user.id;
}

export const updateStorageUser = (data) => {
    var local = getStorageUser()
    local.token = data.token;

    var encrypted = AES.encrypt(JSON.stringify(local), "efi$fm2jms");
    localStorage.setItem(UserData.user, encrypted);
}

export const saveStorageUser = (user) => {
    console.log(`SAVE: ${user}`)
    var encrypted = AES.encrypt(JSON.stringify(user), "efi$fm2jms");
    localStorage.setItem(UserData.user, encrypted);
}

export const getStorageUser = () => {
    var encrypted = localStorage.getItem(UserData.user);
    if (encrypted) {
        try {
            var bytes = AES.decrypt(encrypted, `${"efi$fm2jms"}`);
            var user = JSON.parse(bytes.toString(encUTF8))
            return user ? user : null
        } catch (error) {
            console.log(error)
            localStorage.removeItem(UserData.user)
            console.error("unknow user")
        }
    }
    return null;
}

export const getUserToken = () => {
    var storageUser = getStorageUser();
    return (storageUser) ? storageUser.token : "";
}

export const verifyEmailFormat = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}