export const innovationDefault = {
    name: "",
    origin: "",
    areas: [],
    description: "",
    info: "",
    reference: "",
    user: "",
    create_at: ""
}

export const financingDefault = {
    name: "",
    call: "",
    deadline: "",
    topics: "",
    description: "",
    consortium: "",
    budge: "",
    confinancing: "",
    reference: "",
    user: "",
    create_at: ""
}

export const invitationDefault = {
    description: "",
    date: "",
    programme: "",
    call: "",
    call_deadline: "",
    title: "",
    link: "",
    led_by: "",
    answer_deadline: "",
    decision: "",
    decision_date: "",
    user: "",
    create_at: ""
}

export const proposalsDefault = {
    title: "",
    description: "",
    programme: "",
    call: "",
    call_deadline: "",
    link: "",
    led_by: "",
    collabo_requested: "",
    answer_deadline: "",
    user: "",
    create_at: ""
}

export const projectsDefault = {
    name: "",
    title: "",
    start_year: "",
    end_year: "",
    leader: "",
    web: "",
    user: "",
    create_at: ""
}

export const contacsDefault = {
    name: "",
    type: "",
    country: "",
    web: "",
    description: "",
    collaboration: "",
    contact: "",
    user: "",
    create_at: ""
}

//Type 1 - Text
//Type 2 - Area
//Type 3 - Multiple
//Type 4 - Disabled
//Type 5 - Date

export const innovationFields = [
    { label: "msgTopicNameObl", type: 1, param: "name", maxLength: 128 },
    { label: "msgOrigin", type: 1, param: "origin", maxLength: 128 },
    { label: "msgDescription", type: 2, param: "description", maxLength: 2048 },
    { label: "msgArea", type: 3, param: "areas" },
    { label: "msgWhyTrend", type: 1, param: "info", maxLength: 256 },
    { label: "msgReference", type: 2, param: "reference", maxLength: 256 },
    { label: "msgPostedBy", type: 4, param: "user" },
    { label: "msgWhen", type: 4, param: "create_at" },
]

export const financingFields = [
    { label: "msgProgrammeObl", type: 1, param: "name", maxLength: 128 },
    { label: "msgCall", type: 1, param: "call", maxLength: 128 },
    { label: "msgDeadlineObl", type: 5, param: "deadline" },
    { label: "msgCofinancing", type: 1, param: "confinancing", maxLength: 64 },
    { label: "msgTopics", type: 2, param: "topics", maxLength: 2048 },
    { label: "msgTypeProject", type: 2, param: "description", maxLength: 4096 },
    { label: "msgTypeConsotium", type: 2, param: "consortium", maxLength: 1024 },
    { label: "msgBudget", type: 2, param: "budge", maxLength: 1024 },
    { label: "msgReference", type: 2, param: "reference", maxLength: 256 },
    { label: "msgPostedBy", type: 4, param: "user" },
    { label: "msgWhen", type: 4, param: "create_at" },
]

export const invitationFields = [
    { label: "msgInvitationObl", type: 2, param: "description", maxLength: 512 },
    { label: "msgInvitationDate", type: 5, param: "date" },
    { label: "msgProgrammeObl", type: 1, param: "programme", maxLength: 256 },
    { label: "msgCall", type: 2, param: "call", maxLength: 128 },
    { label: "msgCallDeadlineObl", type: 5, param: "call_deadline" },
    { label: "msgProjectFullTitle", type: 2, param: "title", maxLength: 256 },
    { label: "msgAbstractPage", type: 1, param: "link", maxLength: 128 },
    { label: "msgProjectLed", type: 1, param: "led_by", maxLength: 64 },
    { label: "msgAnswerDeadline", type: 5, param: "answer_deadline" },
    { label: "msgDecision", type: 1, param: "decision", maxLength: 24 },
    { label: "msgDecisionDate", type: 5, param: "decision_date" },
    { label: "msgPostedBy", type: 4, param: "user" },
    { label: "msgWhen", type: 4, param: "create_at" },
]

export const proposalsFields = [
    { label: "msgProjectTitleObl", type: 2, param: "title", maxLength: 256 },
    { label: "msgProjectIdea", type: 2, param: "description", maxLength: 1024 },
    { label: "msgProgramme", type: 2, param: "programme", maxLength: 256 },
    { label: "msgCall", type: 2, param: "call", maxLength: 128 },
    { label: "msgCallDeadlineObl", type: 5, param: "call_deadline" },
    { label: "msgAbstractPage", type: 1, param: "link", maxLength: 128 },
    { label: "msgProjectLed", type: 1, param: "led_by", maxLength: 64 },
    { label: "msgCollaborationRequest", type: 2, param: "collabo_requested", maxLength: 512 },
    { label: "msgAnswerDeadline", type: 5, param: "answer_deadline" },
    { label: "msgPostedBy", type: 4, param: "user" },
    { label: "msgWhen", type: 4, param: "create_at" },
]

export const projectFields = [
    { label: "msgAcronymObl", type: 1, param: "name", maxLength: 128 },
    { label: "msgTitle", type: 2, param: "title", maxLength: 256 },
    { label: "msgStartYearObl", type: 1, param: "start_year", maxLength: 4 },
    { label: "msgEndYear", type: 1, param: "end_year", maxLength: 4 },
    { label: "msgLeader", type: 1, param: "leader", maxLength: 64 },
    { label: "msgWeb", type: 1, param: "web", maxLength: 64 },
    { label: "msgPostedBy", type: 4, param: "user" },
    { label: "msgWhen", type: 4, param: "create_at" },
]

export const contactsFields = [
    { label: "msgEntityObl", type: 1, param: "name", maxLength: 128 },
    { label: "msgEntityType", type: 1, param: "type", maxLength: 128 },
    { label: "msgCountry", type: 1, param: "country", maxLength: 64 },
    { label: "msgWeb", type: 1, param: "web", maxLength: 128 },
    { label: "msgShortDescription", type: 2, param: "description", maxLength: 1024 },
    { label: "msgPotencialCollab", type: 2, param: "collaboration", maxLength: 256 },
    { label: "msgContactPerson", type: 2, param: "contact", maxLength: 128 },
    { label: "msgPostedBy", type: 4, param: "user" },
    { label: "msgWhen", type: 4, param: "create_at" },
]

export const areaOptions = [
    { id: 1, name: "VET - employee" },
    { id: 2, name: "OHS - employee" },
    { id: 3, name: "Governance - employer" },
    { id: 4, name: "Transformation - digital, circular, social..." },
    { id: 5, name: "Ecosystem collaboration" },
]