import React, { useContext } from 'react';
import { UserAction, UserContext } from '../providers/UserProvider';
import { Container, ContainerFlex, Button, Card, TextLocalized } from '../styled/Styled';

export const ModalViewDefault = ({ actionPositive, actionDismiss }) => <Card width="auto" color="color-white">
    <Container px="16" fontWeight="bold" fontSize="20px" textAlign="center" children={<TextLocalized children="msgAreSure" />} />
    <ContainerFlex width="auto" pv="8">
        <Button color="color-black" hoverColor="color-primary-light" ph="64" pv="8" mh="8" fontColor="color-white" fontWeight="bold" onClick={() => actionPositive()} children={<TextLocalized children="actionYes" />} />
        <Button color="color-red" hoverColor="color-red-dark" ph="64" pv="8" mh="8" fontColor="color-white" fontWeight="bold" onClick={() => actionDismiss()} children={<TextLocalized children="actionNo" />} />
    </ContainerFlex>
</Card>

const ModalView = ({ children }) => {
    const { state, dispatch } = useContext(UserContext);
    const _hiddeModal = () => dispatch({ action: UserAction.showModal, data: { ...state.notification, show: false } });

    return <ContainerFlex onClick={_hiddeModal} justify="center" align="center" position="fixed" top="0" elevation="100"
        width="12" height="100%" maxheight="100%" overflowY="scroll" color="color-primary-a50"
        children={children} />;
};

export default ModalView;