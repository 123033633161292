import React, { useContext, useEffect, useState } from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import InnovationItem, { InnovationItemAdd } from '../components/InnovationItem';
import { UserAction, UserContext } from '../components/providers/UserProvider';
import SectionHeader from '../components/SectionHeader';
import { ContainerFlex } from '../components/styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../components/util/HttpConnection';
import { ModalViewDefault } from '../components/util/ModalUtil';

const SectionBaseView = ({ id, label, operation, fields, itemDefault }) => {
    const [search, setSearch] = useState("");
    const [items, setItems] = useState([]);

    const { dispatch } = useContext(UserContext);

    const getItems = () => {
        HttpConnection(operation,
            result => setItems(result.item),
            error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } }),
            HttpMethod.get);
    }

    useEffect(() => {
        //Send request
        getItems();
    }, [operation])

    const showModal = () => {
        dispatch({
            action: UserAction.showModal, data: {
                show: true, body: <InnovationItemAdd
                    operation={operation}
                    fields={fields}
                    itemDefault={{ ...itemDefault }}
                    refreshItems={getItems} />
            }
        });
    }

    const deleteItem = (item) => {
        let actionDismiss = () => dispatch({ action: UserAction.showModal, data: { show: false, body: <div /> } });
        let actionPositive = () => HttpConnection(`${operation}/${item.id}`,
            () => {
                dispatch({ action: UserAction.showNoti, data: { color: "color-green", show: true, message: "msgDeleteSuccess" } });
                getItems();
            },
            error => console.log(error), HttpMethod.delete)
        dispatch({ action: UserAction.showModal, data: { show: true, body: <ModalViewDefault actionPositive={actionPositive} actionDismiss={actionDismiss} /> } });
    }

    const getFilterItems = () => {
        let searchAux = search.toLowerCase();

        switch(operation) {
            case HttpOperations.invitations:
                return items.filter(item => (item.programme.toLowerCase().includes(searchAux) || item.owner.name.toLowerCase().includes(searchAux)))
            case HttpOperations.proposals:
                return items.filter(item => (item.title.toLowerCase().includes(searchAux) || item.owner.name.toLowerCase().includes(searchAux)))
            default:
                return items.filter(item => (item.name.toLowerCase().includes(searchAux) || item.owner.name.toLowerCase().includes(searchAux)))
        }
    }

    return (
        <Fragment>
            <SectionHeader label={label} search={search} setSearch={setSearch} showModal={showModal} />
            <ContainerFlex width="12" px="16" align="stretch">
                {getFilterItems().map((item) => <InnovationItem key={item.id} operation={operation} item={item} fields={fields} deleteItem={() => deleteItem(item)} />)}
            </ContainerFlex>
        </Fragment>
    );
};

export default SectionBaseView;