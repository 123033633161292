import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { Fragment } from 'react';
import { areaOptions } from '../constants/model';
import ButtonFlat from './ButtonFlat';
import { FormDisabledFieldView, FormMultipleSelected, FormTextAreaView, FormTextFieldView } from './InputForm';
import { LanguageContext } from './providers/LanguageProvider';
import { UserAction, UserContext } from './providers/UserProvider';
import { Container, Card, ContainerFlex, TextLocalized, Button } from './styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from './util/HttpConnection';
import { getStorageUser } from './util/UserUtil';

export const InnovationItemAdd = ({ operation, fields, itemDefault, refreshItems }) => {
    const [formData, setFormData] = useState({ ...itemDefault });
    const [isSending, setIsSending] = useState(false);
    const { dispatch } = useContext(UserContext);

    const sendRequest = () => {
        if (checkFiledsLength()) {
            if (checkOblFields()) {
                setIsSending(true)
                //Send request
                HttpConnection(`${operation}`,
                    result => {
                        setFormData({ ...itemDefault });
                        refreshItems();
                        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } })
                        dispatch({ action: UserAction.showModal, data: { show: false } })
                    },
                    error => {
                        //Disable flag
                        setIsSending(false)
                        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } })
                    },
                    HttpMethod.post, formData);
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorLength" } })
        }
    }

    const checkFiledsLength = () => {
        let allLenght = true;
        fields.forEach(field => {
            if (field.maxLength && allLenght) {
                allLenght = (formData[field.param] ? formData[field.param].length : 0) <= field.maxLength
            }
        });
        return allLenght;
    }

    const checkOblFields = () => {
        switch (operation) {
            case HttpOperations.topics:
                return formData.name !== "";
            case HttpOperations.financing:
                return formData.programme !== "" && formData.deadline !== "";
            case HttpOperations.invitations:
                return formData.description !== "" && formData.programme !== "" && formData.call_deadline !== "";
            case HttpOperations.proposals:
                return formData.title !== "" && formData.call_deadline !== "";
            case HttpOperations.projects:
                return formData.name !== "" && formData.start_year !== "";
            case HttpOperations.contacts:
                return formData.name !== "";
            default:
                return true;
        }
    }

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const closeModal = () => dispatch({ action: UserAction.showModal, data: { show: false } })

    return <Card onClick={e => e.stopPropagation()} color="color-white" width="6">
        <ContainerFlex width="12" justify="space-between" align="center">
            <Container px="16" fontSize="42px" fontWeight="300" fontColor="color-black" children={<TextLocalized children="actionNew" />} />
            <Button onClick={closeModal} mh="16" fontWeight="600" fontColor="color-black" children={<TextLocalized children="actionClose" />} />
        </ContainerFlex>
        <ContainerFlex pv="8">
            {
                fields.map((field, index) => {
                    switch (field.type) {
                        case 1:
                            return <FormTextFieldView key={index} item={formData} onChange={onChange} field={field} maxLength={field.maxLength ?? null} />
                        case 2:
                            return <FormTextAreaView key={index} item={formData} onChange={onChange} field={field} maxLength={field.maxLength ?? null} />
                        case 3:
                            return <FormMultipleSelected key={index} item={formData} setFormData={setFormData} options={areaOptions} field={field} />
                        case 5:
                            return <FormTextFieldView key={index} item={formData} onChange={onChange} field={field} type="date" />
                    }
                })
            }
            <ContainerFlex width="12">
                <ButtonFlat isSending={isSending} width="wrap" mv="16" mh="16" action={sendRequest} label="actionCreate" fontColor="color-white" />
            </ContainerFlex>
        </ContainerFlex>
    </Card>
}

const InnovationItem = ({ operation, fields, item, deleteItem }) => {
    const [user, setUser] = useState();
    const [formData, setFormData] = useState({ ...item });
    const [expand, setExpand] = useState(false);
    const [isSending, setIsSending] = useState(false)

    const { dispatch } = useContext(UserContext);
    const { dictionary } = useContext(LanguageContext);

    useEffect(() => {
        setUser(getStorageUser());
    }, [])

    const checkFiledsLength = () => {
        let allLenght = true;
        fields.forEach(field => {
            if (field.maxLength && allLenght)
                allLenght = (formData[field.param] ? formData[field.param].length : 0) <= field.maxLength
        });
        return allLenght;
    }

    const checkOblFields = () => {
        switch (operation) {
            case HttpOperations.topics:
                return formData.name !== "";
            case HttpOperations.financing:
                return formData.programme !== "" && formData.deadline !== "";
            case HttpOperations.invitations:
                return formData.description !== "" && formData.programme !== "" && formData.call_deadline !== "";
            case HttpOperations.proposals:
                return formData.title !== "" && formData.call_deadline !== "";
            case HttpOperations.projects:
                return formData.name !== "" && formData.start_year !== "";
            case HttpOperations.contacts:
                return formData.name !== "";
            default:
                return true;
        }
    }

    const sendRequest = () => {
        if (checkFiledsLength()) {
            if (checkOblFields()) {
                setIsSending(true)
                //Send request
                HttpConnection(`${operation}/${item.id}`,
                    result => {
                        setIsSending(false)
                        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-green", message: "msgSuccess" } })
                    },
                    error => {
                        setIsSending(false)
                        dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorSend" } })
                    },
                    HttpMethod.put, formData);
            } else {
                dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorOblData" } })
            }
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "color-red", message: "errorLength" } })
        }
    }

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const getTitle = () => {
        switch (operation) {
            case HttpOperations.invitations:
                return formData.programme;
            case HttpOperations.proposals:
                return formData.title;
            default:
                return formData.name;
        }
    }

    const getSubTitle = () => {
        switch (operation) {
            case HttpOperations.invitations:
            case HttpOperations.proposals:
                return `${dictionary["msgCallDeadline"]}: ${format(new Date(formData.call_deadline), "dd/MM/yyyy")}`;
            case HttpOperations.financing:
                return `${dictionary["msgDeadline"]}: ${format(new Date(formData.deadline), "dd/MM/yyyy")}`;
            case HttpOperations.projects:
                return `${dictionary["msgStartYear"]}: ${formData.start_year}`;
            default:
                return `${dictionary["msgWhen"]}: ${format(new Date(formData.create_at), "dd/MM/yyyy")}`;
        }
    }

    return (
        <Container width={expand ? "10" : "4"} px="8">
            <Card shadowOn height="100%" width="12" color="color-white" radius="8px">
                <Card flex color={expand ? "color-gray-card" : "color-white"} width="12" ph="16" pv="8" align="center" radius="8px" radiusbl={expand ? "0px" : "8px"} radiusbr={expand ? "0px" : "8px"}>
                    <Container width="wrap" pr="8">
                        <Container mb="8" fontWeight="600" fontSize="17px" children={getTitle()} />
                        <Container fontWeight="300" fontSize="14px" fontColor="color-black-text" children={getSubTitle()} />
                    </Container>

                    <Button onClick={() => setExpand(!expand)} ph="16" pv="8" color="color-black" hoverColor="color-primary-dark" fontColor="color-white" children={<TextLocalized children={!expand ? "actionShow" : "actionClose"} />} />
                </Card>

                {
                    expand ?
                        <Fragment>
                            <Container width="12" height="1px" color="color-gray" />
                            <ContainerFlex pv="8">
                                {
                                    fields.map((field, index) => {
                                        switch (field.type) {
                                            case 1:
                                                return <FormTextFieldView key={index} item={formData} onChange={onChange} field={field} maxLength={field.maxLength} />
                                            case 2:
                                                return <FormTextAreaView key={index} item={formData} onChange={onChange} field={field} maxLength={field.maxLength} />
                                            case 3:
                                                return <FormMultipleSelected key={index} item={formData} setFormData={setFormData} options={areaOptions} field={field} />
                                            case 5:
                                                return <FormTextFieldView key={index} item={formData} onChange={onChange} field={field} type="date" />
                                            default:
                                                return <FormDisabledFieldView key={index} item={formData} field={field} value={field.label === "msgWhen" ? format(new Date(formData.create_at), "dd/MM/yyyy") : formData.owner.name} />
                                        }
                                    })
                                }
                                <ContainerFlex width="12" justify="center">
                                    {
                                        formData.owner.id === user.id
                                            ? <ButtonFlat width="wrap" mv="16" mh="16" action={deleteItem} label="actionDelete" color="color-primary" fontColor="color-white" />
                                            : null
                                    }
                                    <ButtonFlat isSending={isSending} width="wrap" mv="16" mh="16" action={sendRequest} label="actionSave" fontColor="color-white" />
                                </ContainerFlex>

                            </ContainerFlex>
                            <Container width="12" height="1px" color="color-gray" />
                            <Card flex color={"color-gray-card"} width="12" ph="16" pv="8" justify="flex-end" align="center" radius="8px" radiustl={expand ? "0px" : "8px"} radiustr={expand ? "0px" : "8px"}>
                                <Button onClick={() => setExpand(!expand)} ph="16" pv="8" color="color-black"
                                    hoverColor="color-primary-dark" fontColor="color-white" children={<TextLocalized children={!expand ? "actionShow" : "actionClose"} />} />
                            </Card>
                        </Fragment>
                        : null
                }
            </Card>

        </Container>
    );
};

export default InnovationItem;