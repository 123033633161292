import React from 'react';
import { Container, ContainerFlex, TextLocalized, Button } from './styled/Styled';
import name_white from '../img/logo-cenfim.svg'

export const Footer = () => {

    return <Container fontColor="color-primary">
        <ContainerFlex pt="40" justify="center" align="center" color="color-black" fontColor="color-white" fontSize="12px">

            <Container textAlign="center" justify="center" ph="32" width="12" lg="3">
                <Button shadow={false} onClick={() => window.open("https://cenfim.org", '_blank')}>
                    <img width="100%" alt="logo_peu" src={name_white} />
                </Button>
            </Container>

            <ContainerFlex px="32" justify="center" width="12" lg="6">

            </ContainerFlex>

            <ContainerFlex align="center" justify="center" width="12" lg="3" pv="10" ph="32" color="colorblack">
            </ContainerFlex>

            <Container minheight="1px" width="12" color="color-white" />

            <ContainerFlex ph="32" pv="16" width="12" lg="12" textAlign="center" align="center">
                <Container width="auto" fontWeight="bold" children={<TextLocalized children="msgCopyright" />} />

                <Button onClick={() => window.open("https://www.cenfim.org/en/legal-notice", '_blank')} mh="16" mv="8" children={<TextLocalized children="msgFooterLegal" />} />
                <Button onClick={() => window.open("https://www.cenfim.org/en/privacy-policy", '_blank')} mh="16" mv="8" children={<TextLocalized children="msgFooterPrivacy" />} />
                <Button onClick={() => window.open("https://www.cenfim.org/en/cookie-policy", '_blank')} mh="16" mv="8" children={<TextLocalized children="msgFooterCookies" />} />
            </ContainerFlex>
        </ContainerFlex>
    </Container>
}
