import React, { useContext } from 'react';
import ButtonFlat from './ButtonFlat';
import { LanguageContext } from './providers/LanguageProvider';
import { Container, ContainerFlex, CustomInput, TextLocalized } from './styled/Styled';

const SectionHeader = ({ label, search, setSearch, showModal }) => {

    const { dictionary } = useContext(LanguageContext);

    return (
        <Container position="sticky" top="0px" width="12" color="color-white">
            <ContainerFlex width="12" multiline={false} align="center" ph="32" pt="16">
                <Container fontColor="color-black" fontSize="30px" fontWeight="600" letterSapcing="1px" children={<TextLocalized children={label} />} />
                <Container ml="16" height="2px" width="wrap" color="color-black" />
            </ContainerFlex>

            <ContainerFlex width="12" align="center" ph="32" pb="16">
                <ContainerFlex width="wrap" align="center">
                    <Container fontWeight="600" mr="16" children={<TextLocalized children="msgSearch" />} />
                    <CustomInput onChange={e => setSearch(e.target.value)} inputmv="16" titleph="16" title={"msgSearch"} placeholder={dictionary["msgSearch"]} type="text" value={search} />
                </ContainerFlex>

                <Container minwidth="1px" height="40px" mh="16" color="color-black" />

                <ButtonFlat width="auto" ph="16" action={showModal} label="actionNew" fontColor="color-white" />
            </ContainerFlex>
            <Container width="12" height="1px" color="color-gray" />
        </Container>
    );
};

export default SectionHeader;